import { useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuOutlined } from "@ant-design/icons";

import { NavHeader, Navbar, Logo } from "./style";
import Typo from "../Typography";

interface NavProps {
  onNavItemClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const Nav: React.FC<NavProps> = ({ onNavItemClick }) => {
  const { t } = useTranslation("header");

  const [showNavBackground, setShowNavBackground] = useState(false);
  const navRef = useRef(false);
  navRef.current = showNavBackground;
  useLayoutEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 1;
      if (navRef.current !== show) {
        setShowNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <NavHeader showBackground={showNavBackground}>
      <Logo
        id={"nav_logo_btn"}
        onClick={onNavItemClick}
        src={"roodee_full.svg"}
        alt="logo"
      />
      <Navbar mode="horizontal" overflowedIndicator={<MenuOutlined />}>
        <Navbar.Item
          id={"nav_btn_1"}
          key="1"
          onClick={({ domEvent }) => onNavItemClick(domEvent)}
        >
          <Typo weight="bold" hoverable>
            {t("nav-text-1")}
          </Typo>
        </Navbar.Item>
        <Navbar.Item
          id={"nav_btn_2"}
          key="2"
          onClick={({ domEvent }) => onNavItemClick(domEvent)}
        >
          <Typo weight="bold" hoverable>
            {t("nav-text-2")}
          </Typo>
        </Navbar.Item>
        <Navbar.Item
          id={"nav_btn_3"}
          key="3"
          onClick={({ domEvent }) => onNavItemClick(domEvent)}
        >
          <Typo weight="bold" hoverable>
            {t("nav-text-3")}
          </Typo>
        </Navbar.Item>
        <Navbar.Item
          id={"nav_btn_4"}
          key="4"
          onClick={({ domEvent }) => onNavItemClick(domEvent)}
        >
          <Typo weight="bold" hoverable>
            {t("nav-text-4")}
          </Typo>
        </Navbar.Item>
      </Navbar>
    </NavHeader>
  );
};

export default Nav;
