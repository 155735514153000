import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { Background, LayoutHeader, HeaderBox, HeaderTextBox } from "./style";
import { ShadowWarper } from "../Background";
import Typo from "../Typography";
import Space from "../Space";
import Button from "../Button";
import SubPage from "../SubPage";

interface HeaderProps {
  refHeader: React.MutableRefObject<HTMLDivElement | null>;
  onContactButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const Header: React.FC<HeaderProps> = ({ refHeader, onContactButtonClick }) => {
  const { t } = useTranslation("header");
  return (
    <>
      <SubPage />
      <ShadowWarper>
        <Background>
          <LayoutHeader ref={refHeader}>
            <HeaderBox>
              <HeaderTextBox>
                <h1>
                  <Typo size="hero" weight="bold">
                    {t("primary-text-1")}
                  </Typo>
                  <Typo size="sub-hero" display="block">
                    {t("primary-text-2")}
                  </Typo>
                  <Typo size="sub-hero" display="block">
                    {t("primary-text-3")}
                  </Typo>
                </h1>
                <Button
                  id="contact_us_btn"
                  title={t("button")}
                  forward
                  onClick={onContactButtonClick}
                />
              </HeaderTextBox>
            </HeaderBox>
          </LayoutHeader>
        </Background>
        <LayoutHeader>
          <Space margin={[240, 0, 100, 0]}>
            <Row justify="space-between">
              <Col xs={24} md={11}>
                <Typo
                  size="sub-header"
                  weight="bold"
                  color="secondary"
                  display="block"
                  margin={[0, 0, 20, 0]}
                >
                  {t("secondary-text-1")}
                </Typo>
                <Typo size="header" weight="bold" color="primary">
                  {t("secondary-text-2")}
                </Typo>
              </Col>
              <Col xs={24} md={11}>
                <Row>
                  <Space margin={[20, 0]}>
                    <Typo size="sub-header" color="primary">
                      {t("secondary-text-3")}
                    </Typo>
                  </Space>
                  <Space margin={[20, 0]}>
                    <Typo size="sub-header" color="secondary">
                      {t("secondary-text-4")}
                    </Typo>
                  </Space>
                  <Space margin={[20, 0]}>
                    <Typo size="sub-header" color="primary">
                      {t("secondary-text-5")}
                    </Typo>
                  </Space>
                </Row>
              </Col>
            </Row>
          </Space>
        </LayoutHeader>
      </ShadowWarper>
    </>
  );
};

export default Header;
