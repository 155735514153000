import { Img, CardLayout, Layout } from "./style";
import CardList from "../CardList";

const SubPageBottom: React.FC = () => {
  return (
    <Layout>
      <Img src="calendar.png" alt="calendar" />
    </Layout>
  );
};

export default SubPageBottom;
