import { createGlobalStyle } from "styled-components";

import theme from "./theme"; // <-- might have defined many theme variables in here to use within the body styling

const { font } = theme;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  #root {
    position: relative;
    overflow: hidden;
  }

  body {
    font-family: ${font.family};
    font-weight: 400;
    line-height: 1.618;
    color: ${font.color};
  }

  .ant-menu-vertical {
    background-color: transparent !important;
  }

  .ant-menu {
    box-shadow: none;
  }

  .ant-menu-vertical > .ant-menu-item-only-child:active {
    background-color: transparent !important;
  }

  .ant-menu-submenu-popup {
    background-color: ${theme.color.color3} !important;
  }

  .ant-menu-vertical > .ant-menu-item-only-child {
    border-left: 2px solid transparent !important;
  }

  .ant-menu-vertical > .ant-menu-item-only-child:hover {
    border-left: 2px solid white !important;
  }

  .ant-menu-vertical > .ant-menu-item-selected {
    background-color: transparent !important;
    border-left: 2px solid white !important;
  }

  @media screen and (max-width: ${theme.screen.medium}) {
    html {
      font-size: 14px;
    }
  }

  @media screen and (max-width: ${theme.screen.small}) {
    html {
      font-size: 12px;
    }
  }
`;
