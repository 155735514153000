import { Text } from "./style";
import { marginArray } from "../../types";
import { getMarginFromArray } from "../../functions/margin";

export interface TypographyProps {
  size?: "normal" | "title" | "sub-header" | "header" | "sub-hero" | "hero";
  weight?: "bold" | "regular";
  color?: "primary" | "secondary" | "default";
  display?: "block" | "inline-block" | "inline";
  margin?: marginArray;
  hoverable?: boolean;
}

const Typography: React.FC<TypographyProps> = ({
  children,
  size,
  weight,
  color,
  margin,
  display = "inline",
  hoverable = false,
}) => {
  let fontSize = "1rem";
  switch (size) {
    case "hero":
      fontSize = "3.4rem";
      break;
    case "sub-hero":
      fontSize = "1.4rem";
      break;
    case "header":
      fontSize = "2rem";
      break;
    case "sub-header":
      fontSize = "1.2rem";
      break;
  }

  let fontWeight = 400;
  switch (weight) {
    case "bold":
      fontWeight = 600;
      break;
  }

  let fontColor = "white";
  switch (color) {
    case "primary":
      fontColor = "#163A92";
      break;
    case "secondary":
      fontColor = "#58CDCE";
      break;
  }

  const marginString = margin && getMarginFromArray(margin);

  const textOption = {
    fontSize,
    fontWeight,
    fontColor,
    fontDisplay: display,
    fontMargin: marginString,
    hoverable,
  };

  return <Text {...textOption}>{children}</Text>;
};

export default Typography;
