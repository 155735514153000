import styled from "styled-components";

export const LayoutContent = styled.div`
  background: linear-gradient(
    180deg,
    rgba(229, 229, 229, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const BackgroundContent = styled.div`
  background: linear-gradient(
      0.13deg,
      rgba(88, 205, 206, 0.44) -9.88%,
      rgba(255, 255, 255, 0) 48.49%
    ),
    #163a92;
  background-size: cover;
  background-position: top;

  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);

  @media screen and (max-width: ${props => props.theme.screen.small}) {
    clip-path: polygon(0 5%, 100% 0, 100% 95%, 0 100%);
  }
`;

export const ContentBox = styled.div`
  padding: 100px 10%;
`;

export const ContentCenter = styled.div`
  text-align: center;
`;

export const ContentImg = styled.img`
  width: 100%;
`;
