import styled from "styled-components";
import { miniScale } from "../../animations/scale";

export const Layout = styled.div`
  position: absolute;
  top: 10rem;
  right: 0;
  z-index: 1;
  padding-bottom: 5rem;

  @media screen and (min-width: ${(props) => props.theme.screen.xlarge}) {
    transform: scale(1.1, 1.1);
    transform-origin: right bottom;
  }

  @media screen and (max-width: 1200px) {
    transform: translateX(25%);
  }

  @media screen and (max-width: ${(props) => props.theme.screen.large}) {
    transform: translateX(55%);
  }

  @media screen and (max-width: ${(props) => props.theme.screen.medium}) {
    transform: scale(0.8, 0.8) translate(5%, 13%);
    transform-origin: right bottom;
  }

  @media screen and (max-width: ${(props) => props.theme.screen.small}) {
    transform: scale(0.75, 0.75) translate(25%, 20%);
    transform-origin: right bottom;
  }
`;

export const CardLayout = styled.div`
  position: absolute;
  z-index: 5;
  top: 10%;
  left: 0;
  width: 16.5625rem;
  min-width: 231.875px;

  object:not(:last-child):not(:first-child) {
    margin: -25px 0px;
  }

  object:first-child {
    margin-bottom: -25px;
  }

  object:last-child {
    margin-top: -25px;
  }
`;

export const Img = styled.img`
  width: 597px;
  height: 715.88px;
  padding-left: 50px;

  /* &:hover {
    animation: ${miniScale} 0.15s linear 0s 1 normal forwards;
  } */
`;
