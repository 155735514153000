import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { LayoutContent, ContentImgSymbol } from "./style";
import Typo from "../../Typography";
import Space from "../../Space";

interface Content5Props {
  refContactUs: React.MutableRefObject<HTMLDivElement | null>;
}

const Content5: React.FC<Content5Props> = ({ refContactUs }) => {
  const { t } = useTranslation("content5");
  return (
    <LayoutContent>
      <Typo size="header" weight="bold" color="secondary">
        {t("title")}
      </Typo>
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} lg={7}>
          <Space margin={[20, 0]}>
            <Typo
              weight="bold"
              color="primary"
              display="block"
              margin={[10, 0]}
            >
              {t("row1-col1")}
            </Typo>
            <Typo color="primary">{t("row2-col1")}</Typo>
          </Space>
        </Col>
        <Col xs={24} lg={7}>
          <Space margin={[20, 0]}>
            <Typo
              weight="bold"
              color="primary"
              display="block"
              margin={[10, 0]}
            >
              {t("row1-col2")}
            </Typo>
            <Typo color="primary">{t("row2-col2")}</Typo>
          </Space>
        </Col>
        <Col xs={24} lg={7}>
          <Space margin={[20, 0]}>
            <Typo
              weight="bold"
              color="primary"
              display="block"
              margin={[10, 0]}
            >
              {t("row1-col3")}
            </Typo>
            <Typo color="primary">{t("row2-col3")}</Typo>
          </Space>
        </Col>
      </Row>
      <div ref={refContactUs} style={{ height: "12vh" }} />
      <Typo
        size="header"
        weight="bold"
        color="secondary"
        display="block"
        margin={[0, 0, 20, 0]}
      >
        {t("title2")}
      </Typo>
      <Typo
        weight="bold"
        color="primary"
        display="inline-block"
        margin={[30, 30, 0, 0]}
      >
        <ContentImgSymbol src="phone_symbol.svg" alt="phone_symbol" />
        <a href="tel:0894440595">089-444-0595</a>
      </Typo>
      <Typo
        weight="bold"
        color="primary"
        display="inline-block"
        margin={[30, 30, 0, 0]}
        hoverable
      >
        <ContentImgSymbol src="massage_symbol.svg" alt="massage_symbol" />
        <a href="mailto:contact@roodeelms.com,march@roodeelms.com,nutcha.s@roodeelms.com?subject=Contact%20Us">
          contact@roodeelms.com
        </a>
      </Typo>
    </LayoutContent>
  );
};

export default Content5;
