import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import {
  LayoutContent,
  ContentVideo,
  IFrameContainer,
  ResponsiveIFrame,
} from "./style";
import Typo from "../../Typography";
import Space from "../../Space";

interface Content1Props {
  refContent1: React.MutableRefObject<HTMLDivElement | null>;
}

const Content1: React.FC<Content1Props> = ({ refContent1 }) => {
  const { t } = useTranslation("content1");
  return (
    <LayoutContent ref={refContent1}>
      <Typo
        size="header"
        color="primary"
        weight="bold"
        display="block"
        margin={[0, 0, 20, 0]}
      >
        {t("title")}
      </Typo>
      <ContentVideo>
        <IFrameContainer>
          <ResponsiveIFrame
            title="video"
            src="https://player.vimeo.com/video/543518313"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></ResponsiveIFrame>
        </IFrameContainer>
      </ContentVideo>

      {/* <ContentVideo>
        <iframe
          src="https://player.vimeo.com/video/543518313"
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
        <ContentPlaceholder
          src="video_placeholder.svg"
          alt="video_placeholder"
        />
      </ContentVideo> */}
      <Space margin={[30, 0, 30, 0]}>
        <Row justify="space-around">
          <Col xs={24} sm={11} md={5}>
            <Space margin={[10, 0, 10, 0]}>
              <img src={"icon_1.svg"} alt="icon_1" />
              <Typo weight="bold" color="primary" display="block">
                {t("row1-col1")}
              </Typo>
              <Typo color="primary" display="block">
                {t("row2-col1")}
              </Typo>
            </Space>
          </Col>
          <Col xs={24} sm={11} md={5}>
            <Space margin={[10, 0, 10, 0]}>
              <img src={"icon_2.svg"} alt="icon_2" />
              <Typo weight="bold" color="primary" display="block">
                {t("row1-col2")}
              </Typo>
              <Typo color="primary" display="block">
                {t("row2-col2")}
              </Typo>
            </Space>
          </Col>
          <Col xs={24} sm={11} md={5}>
            <Space margin={[10, 0, 10, 0]}>
              <img src={"icon_3.svg"} alt="icon_3" />
              <Typo weight="bold" color="primary" display="block">
                {t("row1-col3")}
              </Typo>
              <Typo color="primary" display="block">
                {t("row2-col3")}
              </Typo>
            </Space>
          </Col>
          <Col xs={24} sm={11} md={5}>
            <Space margin={[10, 0, 10, 0]}>
              <img src={"icon_4.svg"} alt="icon_4" />
              <Typo weight="bold" color="primary" display="block">
                {t("row1-col4")}
              </Typo>
              <Typo color="primary" display="block">
                {t("row2-col4")}
              </Typo>
            </Space>
          </Col>
        </Row>
      </Space>
    </LayoutContent>
  );
};

export default Content1;
