import styled from "styled-components";

interface TextProps {
  fontSize: string;
  fontWeight: number;
  fontColor: string;
  fontDisplay: string;
  fontMargin: string | undefined;
  hoverable: boolean;
}

export const Text = styled.span<TextProps>`
  display: ${props => props.fontDisplay};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.fontColor};
  margin: ${props => props.fontMargin};

  a {
    color: ${props => props.fontColor};
  }
  cursor: ${props => (props.hoverable ? "pointer" : "default")};
`;
