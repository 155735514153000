import styled from "styled-components";
import Button from "../../Button";

export const BackgroundContent = styled.div`
  background: linear-gradient(
      307.14deg,
      rgba(88, 205, 206, 0.44) 7.22%,
      rgba(255, 255, 255, 0) 65.38%
    ),
    #163a92;
  background-size: cover;
  background-position: top;

  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);

  @media screen and (max-width: ${props => props.theme.screen.small}) {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  }
`;

export const LayoutContent = styled.div`
  padding: 12.5rem 10% 40px 10%;
`;

export const ContentButton = styled(Button)``;
