import styled from "styled-components";

export const LayoutContent = styled.div`
  padding: 6rem 10%;
  background-color: #e5e5e5;
`;

export const ContentPlaceholder = styled.img`
  height: 80%;
  width: 95%;
`;

export const ContentVideo = styled.div`
  /* display: inline-block;
  text-align: center;
  margin: 30px 0px;
  width: 100%;
  height: 50vh; */
  background: #0d2c77;
`;

export const IFrameContainer = styled.div`
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  max-height: 506px;
  padding-top: clamp(
    15px,
    56.25%,
    506px
  ); /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
`;
export const ResponsiveIFrame = styled.iframe`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 900px;
  max-height: 506px;
  transform: translateY(-50%);
`;
