import styled from "styled-components";

export const LayoutContent = styled.div`
  background-color: white;
  padding: 250px 10% 150px 10%;
`;

export const ContentImgSymbol = styled.img`
  margin-right: 8px;
`;
