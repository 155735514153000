import styled from "styled-components";

export const LayoutContent = styled.div`
  padding: 100px 10%;
  background-color: white;
`;

export const ContentImg = styled.img`
  width: 100%;
`;
