const theme = {
  font: {
    family: "'Prompt', sans-serif",
    color: "white",
    base: "1rem",
  },
  px_to_rem_multiplier: 0.0625,
  color: {
    red: "red",
    white: "white",
    color2: "#58CDCE",
    color3: "#163A92",
  },
  screen: {
    small: "576px",
    medium: "768px",
    large: "992px",
    xlarge: "1440px",
  },
  spacing: {
    default: "20px",
    half: "10px",
    quarter: "5px",
    double: "40px",
  },
};

export default theme;
