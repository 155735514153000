import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import {
  LayoutContent,
  BackgroundContent,
  ContentBox,
  ContentCenter,
  ContentImg,
} from "./style";
import { ShadowWarper } from "../../Background";
import Typo from "../../Typography";
import Space from "../../Space";

interface Content2Props {
  refContent2: React.MutableRefObject<HTMLDivElement | null>;
}

const Content2: React.FC<Content2Props> = ({ refContent2 }) => {
  const { t } = useTranslation("content2");
  return (
    <LayoutContent ref={refContent2}>
      <ShadowWarper>
        <BackgroundContent>
          <ContentBox>
            <ContentCenter>
              <Typo size="header" weight="bold">
                {t("title")}
              </Typo>
            </ContentCenter>
            <Row justify="space-around" align="middle">
              <Col xs={24} sm={11}>
                <Space margin={[20, "auto"]} maxWidth="400px">
                  <ContentImg src="feature_1.png" alt="feature_1" />
                </Space>
              </Col>
              <Col xs={24} sm={11}>
                <Space margin={[20, "auto"]} maxWidth="400px">
                  <Typo weight="bold">{t("row1-col2")}</Typo>
                  <Space margin={[20, 0, 0, 0]}>
                    <Typo>{t("row2-col2")}</Typo>
                  </Space>
                </Space>
              </Col>
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 11, order: 1 }}>
                <Space margin={[20, "auto"]} maxWidth="400px">
                  <Typo weight="bold">{t("row3-col1")}</Typo>
                  <Space margin={[20, 0, 0, 0]}>
                    <Typo>{t("row4-col1")}</Typo>
                  </Space>
                </Space>
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 11, order: 2 }}>
                <Space margin={[20, "auto"]} maxWidth="400px">
                  <ContentImg src="feature_2.png" alt="feature_2" />
                </Space>
              </Col>
              <Col xs={24} sm={{ span: 11, order: 2 }}>
                <Space margin={[20, "auto"]} maxWidth="400px">
                  <ContentImg src="feature_3.png" alt="feature_3" />
                </Space>
              </Col>
              <Col xs={24} sm={{ span: 11, order: 2 }}>
                <Space margin={[20, "auto"]} maxWidth="400px">
                  <Typo weight="bold">{t("row5-col2")}</Typo>
                  <Space margin={[20, 0, 0, 0]}>
                    <Typo>{t("row6-col2")}</Typo>
                  </Space>
                </Space>
              </Col>
            </Row>
          </ContentBox>
        </BackgroundContent>
      </ShadowWarper>
    </LayoutContent>
  );
};

export default Content2;
