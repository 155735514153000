import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const LayoutButton = styled.div`
  backface-visibility: hidden;
  transition: all 0.2;
  .ant-btn:hover,
  .ant-btn:focus {
    border-color: transparent;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  .ant-btn:active {
    border-color: transparent;
    transform: translateY(1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 30px;
  border-color: transparent;
  height: 40px;
  padding: 8px 25px;
  color: white;
`;

export const ForwardIcon = styled(RightOutlined)`
  float: right;
  padding: 4px 0px;
  margin-left: 8px;
  color: white;
`;
