import styled from "styled-components";

export const LayoutContent = styled.div`
  background-color: #e5e5e5;
  padding: 50px 10%;
`;

export const ContentImg = styled.img`
  width: 100px;
  margin-bottom: 1.6rem;
`;

export const ContentImgSymbol = styled.img`
  margin-right: 8px;
`;
