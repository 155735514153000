import { Img, Layout, CardLayout } from "./style";
import CardList from "../../components/CardList";

const SubPage: React.FC = () => {
  return (
    <Layout>
      <Img src="sub_page.png" alt="sub_page" />
    </Layout>
  );
};

export default SubPage;
