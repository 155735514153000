import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { LayoutContent, ContentImg, ContentImgSymbol } from "./style";
import Typo from "../Typography";
import Space from "../Space";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation("footer");
  return (
    <LayoutContent>
      <Row justify="space-between">
        <Col xs={24} sm={24}>
          <ContentImg src="roodee_full_dark.svg" alt="icon_dark" />
        </Col>
        <Col xs={24} sm={8}>
          <Typo
            weight="bold"
            color="primary"
            display="block"
            margin={[0, 0, 20, 0]}
          >
            {t("row1-col2")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0]}>
            {t("row2-col2")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0]}>
            {t("row3-col2")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0, 20, 0]}>
            {t("row4-col2")}
          </Typo>
        </Col>
        <Col xs={24} sm={8}>
          <Typo
            weight="bold"
            color="primary"
            display="block"
            margin={[0, 0, 20, 0]}
          >
            {t("row1-col3")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0]}>
            {t("row2-col3")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0]}>
            {t("row3-col3")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0]}>
            {t("row4-col3")}
          </Typo>
          <Typo color="primary" display="block" margin={[10, 0, 20, 0]}>
            {t("row5-col3")}
          </Typo>
        </Col>
        <Col xs={24} sm={8}>
          <Typo
            weight="bold"
            color="primary"
            display="block"
            margin={[0, 0, 20, 0]}
          >
            {t("row1-col4")}
          </Typo>
          <Typo weight="bold" color="primary" display="block" margin={[10, 0]}>
            <ContentImgSymbol src="phone_symbol.svg" alt="phone_symbol" />
            089-014-9911
          </Typo>
          <Typo weight="bold" color="primary" display="block" margin={[10, 0]}>
            <ContentImgSymbol src="massage_symbol.svg" alt="massage_symbol" />
            <a href="mailto:contact@roodeelms.com,march@roodeelms.com,nutcha.s@roodeelms.com?subject=Contact%20Us">
              contact@roodeelms.com
            </a>
          </Typo>
        </Col>
      </Row>
      <Space margin={[100, 0, 0, 0]}>
        <Row>
          <Typo weight="bold" color="primary">
            {t("footer1")}
          </Typo>
          <Typo color="primary" margin={[0, 10]}>
            |
          </Typo>
          <Typo weight="bold" color="primary">
            {t("footer2")}
          </Typo>
          <Typo color="primary" margin={[0, 10]}>
            |
          </Typo>
          <Typo weight="bold" color="primary">
            {t("footer3")}
          </Typo>
        </Row>
      </Space>
    </LayoutContent>
  );
};

export default Footer;
