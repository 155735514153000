import styled from "styled-components";
import { Menu } from "antd";

export const Background = styled.div`
  height: 100vh;
  background: linear-gradient(
      342.11deg,
      rgba(88, 205, 206, 0.44) 23.55%,
      rgba(255, 255, 255, 0) 69.14%
    ),
    #163a92;
  background-size: cover;
  background-position: top;

  clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);

  @media screen and (max-width: ${props => props.theme.screen.small}) {
    clip-path: polygon(0 0, 100% 0, 100% 90vh, 0 100%);
  }
`;

export const LayoutHeader = styled.header`
  padding: 30px 10%;
`;

export const NavHeader = styled.nav`
  position: fixed;
  padding: 30px 10%;
  width: 100%;
  z-index: 1;

  .ant-menu {
    color: ${props => props.theme.color.white};
    background: none;
    text-align: right;
  }
  .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-menu-light {
    float: right;
    margin: 0;
  }

  .ant-menu-submenu {
    margin: 0 !important;
    border-bottom: none !important;
  }

  .anticon {
    margin: 0;
  }
`;

export const Navbar = styled(Menu)`
  @media screen and (max-width: ${props => props.theme.screen.medium}) {
    width: 100px;
  }
`;

export const Logo = styled.img`
  float: left;
  padding: 8px 0;
`;

export const HeaderBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const HeaderTextBox = styled.div`
  position: absolute;
  width: 30rem;
  top: 50%;
  left: 0%;
  transform: translate(-0%, -50%);

  @media screen and (max-width: ${props => props.theme.screen.medium}) {
    top: 35%;
    width: 400px;
  }

  @media screen and (max-width: ${props => props.theme.screen.small}) {
    transform: translate(-0%, -50%) scale(0.9, 0.9);
    transform-origin: left top;
  }
`;
