import { marginArray } from "../../types";
import { getMarginFromArray } from "../../functions/margin";

export interface SpaceProps {
  margin?: marginArray;
  maxWidth?: string;
  minWidth?: string;
}

const Space: React.FC<SpaceProps> = ({
  children,
  margin,
  maxWidth,
  minWidth,
}) => {
  const marginString = margin && getMarginFromArray(margin);

  return (
    <div style={{ margin: marginString, maxWidth, minWidth }}>{children}</div>
  );
};

export default Space;
