import { LayoutButton, StyledButton, ForwardIcon } from "./style";

interface ButtonProps {
  id?: string;
  title: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  size?: any;
  forward?: boolean;
}

const Button: React.FC<ButtonProps> = ({ title, forward, ...props }) => {
  return (
    <LayoutButton>
      <StyledButton {...props} icon={forward && <ForwardIcon />}>
        {title}
      </StyledButton>
    </LayoutButton>
  );
};

export default Button;
