import { ShadowWrapper as StyledWrapper } from "./style";

export interface BackgroundProps {}

export const ShadowWarper: React.FC = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

const Background: React.FC<BackgroundProps> = ({ children }) => {
  return <div>{children}</div>;
};

export default Background;
