import styled from "styled-components";
import { scale } from "../../animations/scale";

export const Img = styled.object`
  width: 100%;

  &:hover {
    animation: ${scale} 0.25s linear 0s 1 normal forwards;
  }
`;
