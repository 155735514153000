import { Img } from "./style";
import { cardType } from "../../types";

interface CardProps {
  type: cardType;
}

const Card: React.FC<CardProps> = ({ type }) => {
  return <Img data={`cards/${type}.svg`} type="image/svg+xml" />;
};

export default Card;
