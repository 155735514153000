import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    header: {
      "nav-text-1": "Our Solution",
      "nav-text-2": "Our Solution",
      "nav-text-3": "Our Solution",
      "nav-text-4": "Our Solution",
      "primary-text-1": "",
      "primary-text-2": "",
      "primary-text-3": "",
      "secondary-text-1": "",
      "secondary-text-2": "",
      "secondary-text-3": "",
      "secondary-text-4": "",
      "secondary-text-5": "",
      button: "",
      "row1-col1": "",
      "row2-col1": "",
      "row1-col2": "",
      "row2-col2": "",
      "row1-col3": "",
      "row2-col3": "",
      "row1-col4": "",
      "row2-col4": "",
    },
    content1: {
      title: "",
      "row1-col1": "",
      "row2-col1": "",
      "row1-col2": "",
      "row2-col2": "",
      "row1-col3": "",
      "row2-col3": "",
      "row1-col4": "",
      "row2-col4": "",
    },
    content2: {
      title: "",
    },
    content3: {
      "row1-col1": "",
      "row2-col1": "",
      "row1-col2": "",
      "row2-col2": "",
      "row1-col3": "",
      "row2-col3": "",
    },
    content4: {
      title: "",
      row1: "",
      row2: "",
    },
    content5: {
      title: "?",
      "row1-col1": "",
      "row2-col1": "",
      "row1-col2": "",
      "row2-col2": "",
      "row1-col3": "",
      "row2-col3": "",
    },
    footer: {
      "row1-col1": "",
      "row1-col2": "",
      "row2-col2": "",
      "row3-col2": "",
      "row4-col2": "",
      "row1-col3": "",
      "row2-col3": "",
      "row3-col3": "",
      "row4-col3": "",
      "row5-col3": "",
      "row1-col4": "",
      footer1: "",
      footer2: "",
      footer3: "",
    },
  },
  th: {
    header: {
      "nav-text-1": "ระบบของเรา",
      "nav-text-2": "ฟีเจอร์ของเรา",
      "nav-text-3": "ทำไมต้อง Roodee?",
      "nav-text-4": "ติดต่อเรา",
      "primary-text-1": `ระบบการจัดการการ เรียนออนไลน์`,
      "primary-text-2": "เพื่อสถาบันการศึกษา, รร.กวดวิชา,",
      "primary-text-3": "หน่วยงานราชการ, บริษัทเอกชน และองค์กรต่างๆ",
      "secondary-text-1": "มิติใหม่ของระบบการจัดการการเรียนการสอน (LMS)",
      "secondary-text-2":
        "ระบบออนไลน์ที่จะช่วยให้การเรียน การอบรมมีประสิทธิภาพมากขึ้น และสามารถเชื่อต่อผู้เรียน, ผู้สอน และผู้บริการได้อีกด้วย",
      "secondary-text-3":
        "RooDee LMS จะช่วยให้การเรียน การอบรมมี ประสิทธิภาพมากขึ้น และสามารถเชื่อมต่อผู้เรียน, ผู้สอน และผู้บริหาร ได้อีกด้วย",
      "secondary-text-4":
        "RooDee LMS สร้างระบบที่รวบรวมฟีเจอร์ที่ดีที่สุด กับความต้องการของลูกค้าไว้ในระบบเดียว",
      "secondary-text-5":
        "RooDee LMS สามารถจัดการการเรียนการสอนที่แม่นยำ และได้ใช้จริงในชีวิตประจำวัน เพื่อให้เกิดประสิทธิภาพสูงสุดต่อผู้ใช้งาน",
      button: "ติดต่อเรา",
    },
    content1: {
      title: "ระบบของเรา",
      "row1-col1": "ข้อมูลแม่นยำ",
      "row2-col1": "มั่นใจกับการจัดเก็บข้อมูล และข้อมูลสถิติที่แม่นยำ",
      "row1-col2": "ใช้งานง่าย",
      "row2-col2":
        "ระบบมีดีไซน์ที่สวยงาม และใช้งานง่าย เพื่อสามารถใช้งานอย่างสะดวกสบาย และสามารถเรียนรู้การใช้งานได้เร็ว",
      "row1-col3": "ใช้งานบนอุปกรณ์หลายขนาด",
      "row2-col3": "สามารถใช้งานบนคอมพิวเตอร์, มือถือ และแท็บเล็ต",
      "row1-col4": "ปรับแต่งตามภาพลักษณ์องค์กร",
      "row2-col4": "สามารถปรับแต่งให้เข้ากับภาพลักษณ์ ของแต่ละองค์กร",
    },
    content2: {
      title: "ฟีเจอร์ของเรา",
      "row1-col2": "แดชบอร์ดของผู้ใช้",
      "row2-col2":
        "สามารถติดตามความคืบหน้าของภาพรวมของการเรียน และแต่ละวิชาได้อย่างง่ายดาย",
      "row3-col1": "E-learning",
      "row4-col1":
        "สามารถกำหนดเส้นทางการเรียนรู้ และกำหนดให้ผู้เรียนแต่ละคน สามารถเข้าถึงบทเรียนได้อย่างง่ายดาย  พร้อมแสดงความคืบหน้า ของแต่ละบทเรียน",
      "row5-col2": "แบบทดสอบ และแบบสำรวจ",
      "row6-col2":
        "แบบทดสอบก่อน-หลังเรียน, แบบทดสอบวัดความรู้ และแบบสำรวจ ความต้องการ  พร้อมการตรวจข้อสอบอัตโนมัติได้อย่างง่ายดาย",
    },
    content3: {
      "row1-col1": "ระบบเช็คชื่อ",
      "row2-col1":
        "แม้ว่าจะเป็นการเรียนหรืออบรมในห้องเรียน สามารถเช็คชื่อผู้เรียนได้ผ่านระบบได้ ",
      "row1-col2": "รายงาน และการติดตามข้อมูล",
      "row2-col2":
        "สามารถติดตาม และดาวน์โหลดรายงาน หรือสถิติต่างๆ เช่น รายงานผลการเรียนของผู้เรียนแต่ละคน",
      "row1-col3": "ประชาสัมพันธ์ และข่าวสาร",
      "row2-col3":
        "ผู้ดูแลระบบ และผู้สอน สามารถส่งข่าวสารประชาสัมพันธ์ให้กับ ผู้เรียนผ่านระบบ",
    },
    content4: {
      title: "ทำไมต้อง Roodee LMS?",
      row1:
        "ทีมงาน RooDee LMS มุ่งมั่นสำรวจ และศึกษาตลาดอย่างจริงจัง เพื่อพัฒนาระบบ LMS ที่สมบูรณ์ และตอบสนองความต้องการของผู้ใช้",
      row2: "เพื่อสร้างระบบ LMS ที่ดีที่สุดในประเทศไทย",
      button: "เพิ่มเติม",
    },
    content5: {
      title: "พร้อมทดลองระบบแล้วรึยัง?",
      "row1-col1": "ผู้บริหาร",
      "row2-col1":
        "ช่วยให้การบริหารจัดการองค์กรมีความทันสมัยวิเคราะห์ข้อมูลที่แม่นยำเป็นระเบียบและใช้งานง่ายด้วยฟังก์ชั่นการติดตามความ คืบหน้า และการรายงานผลของผู้เรียน",
      "row1-col2": "ผู้สอน",
      "row2-col2":
        "ช่วยให้ผู้สอนทำงานได้ง่ายขึ้นและลดงานเอกสารเพื่อให้มี เวลาเตรียมการสอนและเวลาในการพัฒนาผู้เรียนมากยิ่งขึ้น  ด้วยฟังก์ชั่นเช็คชื่อการเรียนในห้องเรียนการอัพโหลดสื่อ การสอน การแจ้งข่าวสารกับนักเรียน และอื่นๆ",
      "row1-col3": "ผู้เรียน",
      "row2-col3":
        "ผู้เรียนสามารถดูภาพรวม กิจกรรมการเรียนรู้ของตนเอง ได้อย่างง่ายดาย ไม่ว่าจะเป็นการฝึกอบรมในห้องเรียน หรือการเรียนในระบบ E-Learning",
      title2: "ติดต่อเรา RooDee LMS",
    },
    footer: {
      "row1-col1": "",
      "row1-col2": "สินค้าของเรา",
      "row2-col2": "ระบบของเรา",
      "row3-col2": "ฟีเจอร์ของเรา",
      "row4-col2": "ทำไมต้อง Roodee LMS?",
      "row1-col3": "ร่วมงานกับเรา",
      "row2-col3": "ร่วมงานกับ RooDee LMS",
      "row3-col3": "เกี่ยวกับเรา",
      "row4-col3": "ติดต่อเราเพื่อรับข้อมูลเพิ่มเติม",
      "row5-col3": "รายงานปัญหาเกี่ยวกับระบบ",
      "row1-col4": "ติดต่อเรา",
      footer1: "© ROODEE LMS 2021. All Rights Reserved.",
      footer2: "นโยบายความเป็นส่วนตัว",
      footer3: "ข้อกำหนดและเงื่อนไข",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "th",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    whitelist: ["en", "th"],
  });

export default i18n;
