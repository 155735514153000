import styled from "styled-components";
import { Menu } from "antd";

export const NavHeader = styled.nav<{ showBackground: boolean }>`
  background: ${props =>
    props.showBackground ? props.theme.color.color3 : "transparent"};
  position: fixed;
  padding: 30px 10% 0 10%;
  width: 100%;
  z-index: 110;

  .ant-menu {
    color: ${props => props.theme.color.white};
    background: none;
    text-align: right;
  }
  .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-menu-light {
    float: right;
    margin: 0;
  }

  .ant-menu-submenu {
    margin: 0 !important;
    border-bottom: none !important;
  }

  .anticon {
    margin: 0;
  }
`;

export const Navbar = styled(Menu)`
  @media screen and (max-width: ${props => props.theme.screen.medium}) {
    width: 100px;
  }
`;

export const Logo = styled.img`
  cursor: pointer;

  float: left;
  padding: 8px 0;
`;
