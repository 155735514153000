import { ThemeProvider } from "styled-components";
import theme from "./theme";

import Page from "./pages";

import AppStyle from "./App.style";
import "./App.less";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppStyle />
      <Page />
    </ThemeProvider>
  );
};

export default App;
