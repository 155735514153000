import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { LayoutContent, BackgroundContent, ContentButton } from "./style";
import { ShadowWarper } from "../../Background";
import Typo from "../../Typography";
import Space from "../../Space";
import SubPageBottom from "../../SubPageBottom";

interface Content4Props {
  refContent4: React.MutableRefObject<HTMLDivElement | null>;
  onContactButtonClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const Content4: React.FC<Content4Props> = ({
  refContent4,
  onContactButtonClick,
}) => {
  const { t } = useTranslation("content4");
  return (
    <ShadowWarper>
      <SubPageBottom />
      <BackgroundContent>
        <LayoutContent ref={refContent4}>
          <Typo size="header" weight="bold" color="secondary">
            {t("title")}
          </Typo>
          <Row>
            <Col>
              <Space maxWidth="460px" margin={[0, 0, 300, 0]}>
                <Typo>{t("row1")}</Typo>
                <Typo
                  size="sub-hero"
                  weight="bold"
                  display="block"
                  margin={[30, 0]}
                >
                  {t("row2")}
                </Typo>
                <ContentButton
                  id="contact_us_btn"
                  title={t("button")}
                  forward
                  onClick={onContactButtonClick}
                />
              </Space>
            </Col>
          </Row>
        </LayoutContent>
      </BackgroundContent>
    </ShadowWarper>
  );
};

export default Content4;
