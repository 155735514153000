import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { LayoutContent, ContentImg } from "./style";
import Typo from "../../Typography";
import Space from "../../Space";

interface Content3Props {}

const Content3: React.FC<Content3Props> = () => {
  const { t } = useTranslation("content3");
  return (
    <LayoutContent>
      <Row gutter={[24, 24]} justify="space-around">
        <Col xs={24} sm={12} lg={8}>
          <Space margin={[0, "auto"]} maxWidth="400px" minWidth="250px">
            <ContentImg src="feature_4.png" alt="feature_4" />
            <Typo
              weight="bold"
              color="primary"
              display="block"
              margin={[10, 0]}
            >
              {t("row1-col1")}
            </Typo>
            <Typo color="primary">{t("row2-col1")}</Typo>
          </Space>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Space margin={[0, "auto"]} maxWidth="400px" minWidth="250px">
            <ContentImg src="feature_5.png" alt="feature_5" />
            <Typo
              weight="bold"
              color="primary"
              display="block"
              margin={[10, 0]}
            >
              {t("row1-col2")}
            </Typo>
            <Typo color="primary">{t("row2-col2")}</Typo>
          </Space>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <Space margin={[0, "auto"]} maxWidth="400px" minWidth="250px">
            <ContentImg src="feature_6.png" alt="feature_6" />
            <Typo
              weight="bold"
              color="primary"
              display="block"
              margin={[10, 0]}
            >
              {t("row1-col3")}
            </Typo>
            <Typo color="primary">{t("row2-col3")}</Typo>
          </Space>
        </Col>
      </Row>
    </LayoutContent>
  );
};

export default Content3;
