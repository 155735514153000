import theme from "../theme";
import { marginArray } from "../types";

const convertPixelToREM = (pixel: number): number => {
  return pixel * theme.px_to_rem_multiplier;
};

export const getMarginFromArray = (marginArray: marginArray): string => {
  let marginString = "";
  if (marginArray.length === 2) {
    marginString = `
    ${convertPixelToREM(marginArray[0])}rem
    ${
      marginArray[1] === "auto"
        ? "auto"
        : `${convertPixelToREM(marginArray[1])}rem`
    }`;
  } else {
    marginString = `
    ${convertPixelToREM(marginArray[0])}rem
    ${convertPixelToREM(marginArray[1])}rem
    ${convertPixelToREM(marginArray[2])}rem
    ${convertPixelToREM(marginArray[3])}rem`;
  }
  return marginString;
};
