import { useRef } from "react";

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Content1 from "../components/Content/Content1";
import Content2 from "../components/Content/Content2";
import Content3 from "../components/Content/Content3";
import Content4 from "../components/Content/Content4";
import Content5 from "../components/Content/Content5";
import Footer from "../components/Footer";

const App: React.FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const content1Ref = useRef<HTMLDivElement>(null);
  const content2Ref = useRef<HTMLDivElement>(null);
  const content4Ref = useRef<HTMLDivElement>(null);
  const contactUsRef = useRef<HTMLDivElement>(null);

  const handleClickScroll = (event: React.MouseEvent<HTMLElement>) => {
    switch (event.currentTarget.id) {
      case "nav_logo_btn":
        if (headerRef.current) {
          headerRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "nav_btn_1":
        if (content1Ref.current) {
          content1Ref.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "nav_btn_2":
        if (content2Ref.current) {
          content2Ref.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "nav_btn_3":
        if (content4Ref.current) {
          content4Ref.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      case "nav_btn_4":
      case "contact_us_btn":
        if (contactUsRef.current) {
          contactUsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Navbar onNavItemClick={handleClickScroll} />
      <Header refHeader={headerRef} onContactButtonClick={handleClickScroll} />
      <Content1 refContent1={content1Ref} />
      <Content2 refContent2={content2Ref} />
      <Content3 />
      <Content4
        refContent4={content4Ref}
        onContactButtonClick={handleClickScroll}
      />
      <Content5 refContactUs={contactUsRef} />
      <Footer />
    </>
  );
};

export default App;
