import { keyframes } from "styled-components";

export const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.15);
  }
`;

export const miniScale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.05);
  }
`;
