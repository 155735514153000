import Card from "../Card";
import { cardType } from "../../types";

interface CardListProps {
  cards: cardType[];
}

const CardList: React.FC<CardListProps> = ({ cards }) => {
  return (
    <>
      {cards.map(cardType => (
        <Card type={cardType} />
      ))}
    </>
  );
};

export default CardList;
